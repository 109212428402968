import { Log, LogLevel } from '@/interfaces';

export interface AudioElements {
  [key: string]: HTMLAudioElement;
}

export interface State {
  currentSource: string | null;
  currentModuleSources: string[];
  audioElements: AudioElements;
  timer: null | ReturnType<typeof setTimeout>;
}

const initialState: State = {
  currentSource: null,
  currentModuleSources: [],
  audioElements: {},
  timer: null,
};

export default {
  namespaced: true,
  state: () => initialState,
  actions: {
    async sleep({ state }: { state: State }, s: number | null | undefined) {
      return new Promise(resolve => {
        state.timer = window.setTimeout(resolve, s! * 1000);
      });
    },
    addAudio({ state }: { state: State }, { src, log }: { src: string, log: (data: Log) => void }) {
      try {
        const audioElement = new Audio(src);
        audioElement.load();
        state.audioElements[src] = audioElement;
        log({
          logLevel: LogLevel.INFO,
          message: `Audio preloaded successfully: ${src}`,
        });
      } catch (error) {
        log({
          logLevel: LogLevel.ERROR,
          message: `Failed to preload audio: ${src}`,
          context: { error },
        });
      }
    },
    stopAudio({ state }: { state: State }) {
      const audioElement = state.audioElements[state.currentSource!];
      if (audioElement) {
        state.currentSource = null;
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    },
    setAudioSources({ state, dispatch }: any, { sources, log }: { sources: string[], log: (data: Log) => void }) {
      clearTimeout(state.timer);
      state.currentModuleSources = sources;
      dispatch('stopAudio');
      dispatch('playAudio', { current: 0, log });
    },
    async playAudio(
      { state, dispatch, rootGetters }: { state: State; dispatch: any; rootGetters: any },
      { current, log }: { current: number; log: (data: Log) => void; },
    ) {
      if (log) {
        log({
          logLevel: LogLevel.INFO,
          message: `Try play audio with index ${current}`,
        });
      }

      if (state.currentSource !== null) {
        await dispatch('stopAudio');
      }

      const audioElement = state.audioElements[state.currentModuleSources[current]];

      if (!audioElement) {
        if (log) {
          log({
            logLevel: LogLevel.ERROR,
            message: `Not found audio with index ${current}`,
          });
        }

        return;
      }

      state.currentSource = state.currentModuleSources[current];

      if (rootGetters['getDelay']) {
        await dispatch('sleep', rootGetters['getDelay']);
      }

      try {
        await new Promise(res => {
          audioElement.play();
          audioElement.onended = res;
          audioElement.onerror = error => {
            if (log) {
              log({
                logLevel: LogLevel.ERROR,
                message: `Error play audio start: ${audioElement?.src || current}`,
                context: { error },
              });
            }
          };
        });

        if (log) {
          log({
            logLevel: LogLevel.INFO,
            message: `Play audio start: ${audioElement?.src || current}`,
          });
        }

        dispatch('playAudio', { current: current + 1, log });
        state.currentSource = null;
      } catch (error) {
        if (log) {
          log({
            logLevel: LogLevel.ERROR,
            message: `Error play audio start: ${audioElement?.src || current}`,
            context: { error },
          });
        }

        state.currentSource = null;
      }
    },
  },
};
